import React, { useContext } from "react"
import Helmet from "react-helmet"
import Layout from "../layouts/en"
import SPHeader from "../components/static-pages-layout/static-page-header"
import decentralized from "../images/decentralized.png"
import decentralizedDark from "../images/decentralized-dark.png"
import SignatureContext from "../context/signature.context"
import { SIGNATURE_SERVICES } from "../services/signature.service"
import { useSignatureService } from "../components/common/hooks/useSignatureService"

const ConnectEnPage = ({ location }) => {
  const [signatureServiceType, setSignatureServiceType] = useSignatureService()
  const langPaths = { es: "/es/conectar" }
  return (
    <Layout location={location} langPaths={langPaths}>
      <Helmet>
        <title>Connect | DevLand Account</title>
        <meta
          name="description"
          content="Connect to your DevLand User Account by authenticating with your wallet. We currently support the Albedo and LEPK wallets."
        />
      </Helmet>
      <SPHeader
        superTitle="User authentication"
        title="Connect"
        imageLight={decentralized}
        imageDark={decentralizedDark}
      >
        Connect to your DevLand User Account by authenticating with your crypto
        wallet. We currently support the Albedo and LEPK wallets.
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 py-6">
          <button
            className="button md:button-lg button-primary block m-2"
            onClick={() => setSignatureServiceType(SIGNATURE_SERVICES.LEPK)}
          >
            Connect with private key
          </button>
          <button
            className="button md:button-lg button-primary  m-2 hidden md:block"
            onClick={() => setSignatureServiceType(SIGNATURE_SERVICES.ALBEDO)}
          >
            Connect with Albedo
          </button>
          <button
            className="button md:button-lg button-primary  m-2 hidden md:block"
            onClick={() => setSignatureServiceType(SIGNATURE_SERVICES.RABET)}
          >
            Connect with Rabet
          </button>
          <button
            className="button md:button-lg button-primary  m-2 hidden md:block"
            onClick={() =>
              setSignatureServiceType(SIGNATURE_SERVICES.FREIGHTER)
            }
          >
            Connect with Freighter
          </button>
        </div>
      </SPHeader>
    </Layout>
  )
}

export default ConnectEnPage
