import React from "react"
import Helmet from "react-helmet"
import Layout from "../layouts/es"
import SPHeader from "../components/static-pages-layout/static-page-header"
import decentralized from "../images/decentralized.png"
import decentralizedDark from "../images/decentralized-dark.png"
import { SIGNATURE_SERVICES } from "../services/signature.service"
import { useSignatureService } from "../components/common/hooks/useSignatureService"

const ConectarESPage = ({ location }) => {
  const [signatureServiceType, setSignatureServiceType] = useSignatureService()

  const langPaths = { en: "/connect" }
  return (
    <Layout location={location} langPaths={langPaths}>
      <Helmet>
        <title>Conectar | Cuenta de DevLand</title>
        <meta
          name="description"
          content="Conéctate a tu cuenta de usuario de DevLand mediante autenticación con cartera de criptomonedas de Stellar. Soportamos las carteras Albedo y LEPK."
        />
      </Helmet>
      <SPHeader
        superTitle="Autenticación de usuario"
        title="Conectar"
        imageLight={decentralized}
        imageDark={decentralizedDark}
      >
        Conéctate a tu cuenta de usuario de DevLand mediante autenticación con
        cartera de criptomonedas de Stellar. Soportamos las carteras Albedo y
        LEPK.
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 py-6">
          <button
            className="button md:button-lg button-primary block m-2"
            onClick={() => setSignatureServiceType(SIGNATURE_SERVICES.LEPK)}
          >
            Conéctate con clave privada
          </button>
          <button
            className="button md:button-lg button-primary hidden m-2 md:block"
            onClick={() => setSignatureServiceType(SIGNATURE_SERVICES.ALBEDO)}
          >
            Conéctate con Albedo
          </button>
          <button
            className="button md:button-lg button-primary hidden m-2 md:block"
            onClick={() => setSignatureServiceType(SIGNATURE_SERVICES.RABET)}
          >
            Conéctate con Rabet
          </button>
          <button
            className="button md:button-lg button-primary hidden m-2 md:block"
            onClick={() =>
              setSignatureServiceType(SIGNATURE_SERVICES.FREIGHTER)
            }
          >
            Conéctate con Freighter
          </button>
        </div>
      </SPHeader>
    </Layout>
  )
}

export default ConectarESPage
