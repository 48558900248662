import React, { useState } from "react"

const SignatureContext = React.createContext({
  signatureServiceType: "",
})

const SignatureProvider = ({ children }) => {
  const [signatureServiceType, setSignatureServiceType] = useState("")

  return (
    <SignatureContext.Provider
      value={{ signatureServiceType, setSignatureServiceType }}
    >
      {children}
    </SignatureContext.Provider>
  )
}

export default SignatureContext

export { SignatureProvider }
